<template lang="pug">
v-app 
  v-main
    v-container(fluid)
      v-row.justify-center.align-center.h-screen
        v-col.card.p-6(cols="12" sm="9" md="8" lg="6")
          v-row
            v-col(cols="8")
              img.is-fullwidth(src="/images/ReLowCode_Logo.png")
              h1.text-h5.text-center What are you going to build?
              //- buttons
            v-col(cols="4")
              //-Github button
              
              VBtnPrimary.mb-4(block, @click="signIn('github', { callbackUrl: '/app' })")
                v-icon.mr-1(icon="bi:github")
                span Github
                //- Google button
              VBtnPrimary.mb-4(block, @click="signIn('google', { callbackUrl: '/app' })")
                v-icon.mr-1(icon="bi:google")
                span Google
                //- Linkedin button
              VBtnPrimary.mb-4(block, @click="signIn('linkedin', { callbackUrl: '/app' })")
                v-icon.mr-1(icon="bi:linkedin")
                span Linkedin

              VBtnPrimary.mb-4(block, @click="signIn('azure-ad', { callbackUrl: '/app' })")
                v-icon.mr-1(icon="bi:linkedin")
                span Microsoft
              //- line
              div(style='width: 100%; height: 1rem; border-bottom: 2px solid #000; text-align: center')
                span(style='font-size: 1rem; background-color: #eef3fc; padding: 0 10px')
                  | or
              br
              //- Email button
              v-form(ref="formEmail" )
                v-text-field(v-model="email", density="compact",label="Email"  :rules="[ruleRequired,ruleEmail ]"  @keydown.enter.prevent="emailSignin"  )
                VBtnPrimary.mb-4(block, @click="emailSignin")
                  v-icon.mr-1(icon="ic:round-email")
                  span Email
</template>

<style lang="sass">
@import "/assets/custom.sass" 
.button
  background-color: #10046D
  color: white !important
  &:hover
    background-color: #BDBBCF
    color: black !important
.button-narrow 
  width: 250px
.v-text-field .v-input__details
  padding-bottom: 1rem
</style>

<script setup >
const email = ref("")
const { signIn,status,data,signOut } = useAuth()
const { ruleRequired, ruleEmail } = useFormRules();
const formEmail = ref(null)

definePageMeta({ middleware: "guest-only", auth: { authenticatedRedirectTo: "/app" } })

async function emailSignin(){
  let val = await formEmail.value.validate()
  if(val.valid){
    signIn('email', { email: email.value, callbackUrl: '/app' })
  }
}


</script>

// <script>
// import Swal from 'sweetalert2'

// export default {
//   data() {
//     return {
//       email: '',
//     };
//   },
 
//   methods: {
//     async emailSignin(e) {
//       e.preventDefault()
//       

//       if( valJD("femail")){
//         
//         signIn('google', { email: this.email })
//       }
      
//     }
//   }
// }
// </script>

